import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  HeroContentWrapper,
  HeroWrapper,
  HeroContent,
  HeroText,
  HeroContentBack,
  AboutArrow,
} from "./styles"
import { MainWrapper } from "components"

export const Hero = () => {
  const data = useStaticQuery(query)

  return (
    <HeroWrapper
      BG={data.prismicLandingPageDetails.data.main_background_image.url}
    >
      <MainWrapper>
        <HeroContentWrapper>
          <HeroContentBack>
            <HeroContent
              BG={
                data.prismicLandingPageDetails.data
                  .hero_section_background_image.url
              }
            >
              <HeroText>
                <h1>
                  <b>{data.prismicLandingPageDetails.data.hero_title.text}</b>
                </h1>
                <p>
                  {data.prismicLandingPageDetails.data.hero_description.text}
                </p>
              </HeroText>
            </HeroContent>
          </HeroContentBack>
        </HeroContentWrapper>
        <AboutArrow>
          <a style={{ zIndex: "200" }} href="#subscribe">
            <img
              src={data.prismicLandingPageDetails.data.arrow.url}
              alt={data.prismicLandingPageDetails.data.arrow.alt}
            />
          </a>
        </AboutArrow>
      </MainWrapper>
    </HeroWrapper>
  )
}

const query = graphql`
  {
    prismicLandingPageDetails {
      data {
        main_background_image {
          # localFile {
          #   childrenImageSharp {
          #     fluid(quality: 100) {
          #       src
          #     }
          #   }
          # }
          url
        }
        hero_title {
          text
        }
        hero_description {
          text
        }
        hero_section_background_image {
          url
        }
        arrow {
          url
          alt
        }
      }
    }
  }
`

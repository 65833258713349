import styled from "styled-components"

export const FooterWrapper = styled.section`
  background-image: ${({ BG }) => `url(${BG})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`
export const FooterContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 30px 90px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`

export const FooterLogo = styled.div`
  margin-top: 10px;

  @media (max-width: 992px) {
    margin-bottom: 50px;
  }
`

export const NavListOne = styled.div`
  > a {
    font-size: 10px;
  }
`

export const NavListTwo = styled.div``

export const FollowSection = styled.div`
  margin-left: 90px;

  > h1 {
    font-weight: var(--semiBold);
    color: var(--blizzardBlue);
    margin-bottom: 25px;
    font-size: 1.4375rem;
  }

  @media (max-width: 992px) {
    margin-top: 50px;
    margin-left: 0;
  }
`

export const SocialMedia = styled.div`
  display: flex;

  > a {
    padding: 12px 10px 7px 11px;
    border: 1px solid var(--white);
    border-radius: 50%;
    margin-right: 6px;

    :hover {
      transform: translateY(-1px);
    }

    :active {
      transform: translateY(1px);
    }
  }

  > a > svg {
    width: 21px;
  }
`
export const BottomColor = styled.div`
  height: 10px;
  width: 100%;
  background-color: var(--juneBud);
`

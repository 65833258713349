import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { LogoWrapper, ModalWrapper, QRImage, LogoImage } from "./styles"

export const TheModal = () => {
  //data from graphql
  const data = useStaticQuery(query)

  return (
    <ModalWrapper>
      <QRImage
        fixed={
          data.prismicLandingPageDetails.data.qr_image.localFile.childImageSharp
            .fixed
        }
      />
      <h6>{data.prismicLandingPageDetails.data.qr_title.text}</h6>
      <LogoWrapper>
        <LogoImage
          fixed={
            data.prismicLandingPageDetails.data.android_image.localFile
              .childImageSharp.fixed
          }
        />
        <LogoImage
          fixed={
            data.prismicLandingPageDetails.data.apple_image.localFile
              .childImageSharp.fixed
          }
        />
      </LogoWrapper>
    </ModalWrapper>
  )
}

const query = graphql`
  {
    prismicLandingPageDetails {
      data {
        qr_image {
          url
          localFile {
            childImageSharp {
              fixed(width: 200, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        qr_title {
          text
        }
        android_image {
          localFile {
            childImageSharp {
              fixed(width: 25) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        apple_image {
          localFile {
            childImageSharp {
              fixed(width: 25) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`

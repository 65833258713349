import styled from "styled-components"
import Img from "gatsby-image"

export const ModalWrapper = styled.div`
  padding: 20px 30px;

  > h6 {
    text-align: center;
    padding-top: 20px;
  }
`

export const QRImage = styled(Img)``

export const LogoWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center;
`

export const LogoImage = styled(Img)`
  margin: 0 10px;
`

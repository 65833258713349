import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  ItemWrapper,
  SubscribeWrapper,
  LeftContent,
  RightContent,
  SubsForm,
  FormInput,
  SubmitButton,
  LeftContentWrapper,
  LeftContentWidth,
} from "./styles"

export const Subscribe = () => {
  const [subs, setSubs] = useState(null)
  const [message, setMessage] = useState("")

  //handle input data
  const handleInput = e => {
    setSubs({
      email_group: "LoopControlMainSite",
      email: e.target.value,
      unsubscribed: false,
    })
  }

  //handle form submit
  const sendData = async e => {
    e.preventDefault()

    //start spining
    const spining = document.querySelector(".my-spiner")
    spining.classList.add("show")

    //remove button
    document.querySelector("#submit").classList.add("hide")

    try {
      // const response =
      await fetch(
        "https://erp.loop-control.com/api/resource/Email%20Group%20Member",
        {
          method: "post",
          body: JSON.stringify(subs),
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${process.env.AUTHORIZATION_FORM}`,
          },
        }
      )

      // const json = await response.json()
      // console.log("Success:", JSON.stringify(json))

      setMessage("Email sent!")

      //remove spining
      spining.classList.remove("show")
    } catch (error) {
      // console.error("Error:", error)
      setMessage("Something Error!")

      //remove spining
      spining.classList.remove("show")
    }
  }

  const data = useStaticQuery(query)

  return (
    <SubscribeWrapper
      BG={data.prismicLandingPageDetails.data.subscribe_background_image.url}
      id="subscribe"
    >
      <ItemWrapper>
        <LeftContentWrapper>
          <LeftContentWidth>
            <LeftContent>
              <h1>
                {data.prismicLandingPageDetails.data.subscribe_title.text}
              </h1>
              <p>
                {data.prismicLandingPageDetails.data.subscribe_description.text}
              </p>
              <SubsForm onSubmit={sendData}>
                <FormInput
                  type="email"
                  onChange={handleInput}
                  name="email"
                  required
                  placeholder={
                    data.prismicLandingPageDetails.data
                      .subscribe_email_placeholder.text
                  }
                />
                <SubmitButton id="submit" type="submit">
                  {
                    data.prismicLandingPageDetails.data
                      .subscribe_submit_button_text.text
                  }
                </SubmitButton>
                <div className="loader my-spiner"></div>
                <div className="success"> {message}</div>
              </SubsForm>
            </LeftContent>
          </LeftContentWidth>
        </LeftContentWrapper>

        <RightContent
          BG={
            data.prismicLandingPageDetails.data
              .subscribe_section_background_image.url
          }
        >
          <img
            alt={
              data.prismicLandingPageDetails.data
                .subscribe_section_background_image.alt
            }
            src={
              data.prismicLandingPageDetails.data
                .subscribe_section_background_image.url
            }
          />
        </RightContent>
      </ItemWrapper>
    </SubscribeWrapper>
  )
}

const query = graphql`
  {
    prismicLandingPageDetails {
      data {
        subscribe_background_image {
          # localFile {
          #   childImageSharp {
          #     fluid {
          #       src
          #     }
          #   }
          # }
          url
          alt
        }
        subscribe_email_placeholder {
          text
        }
        subscribe_section_background_image {
          url
        }
        subscribe_submit_button_text {
          text
        }
        subscribe_title {
          text
        }
        subscribe_description {
          text
        }
      }
    }
  }
`

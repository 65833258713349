import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { isAndroid, isIOS } from "react-device-detect"
import Modal from "react-modal"

import {
  FooterWrapper,
  FooterContentWrapper,
  FooterLogo,
  NavListOne,
  NavListTwo,
  FollowSection,
  SocialMedia,
  BottomColor,
} from "./styles"
import { MainWrapper, DefaultLink, TheModal, SimpleButton } from "components"
import Logo from "./Logo"
import Copyright from "./Copyright"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "99999",
    overflow: "hidden",
  },
}

const Footer = () => {
  const data = useStaticQuery(query)

  //for modal
  const [modalIsOpen, setModalIsOpen] = useState(false)

  function openModal() {
    setModalIsOpen(true)
  }

  function closeModal() {
    setModalIsOpen(false)
  }

  return (
    <FooterWrapper
      BG={data.prismicLandingPageDetails.data.subscribe_background_image.url}
    >
      <MainWrapper>
        <FooterContentWrapper>
          <FooterLogo>
            <Logo />
          </FooterLogo>
          <NavListOne>
            <ul>
              <li>
                <DefaultLink font="14px" color="var(--white)" to="/">
                  Home
                </DefaultLink>
              </li>
              <li>
                <DefaultLink font="14px" color="var(--white)" to="/#about">
                  About
                </DefaultLink>
              </li>
              <li>
                <DefaultLink font="14px" color="var(--white)" to="/#subscribe">
                  Subscribe
                </DefaultLink>
              </li>
              <li>
                <DefaultLink font="14px" color="var(--white)" to="/#contact">
                  Get in touch
                </DefaultLink>
              </li>
            </ul>
          </NavListOne>
          <NavListTwo>
            <ul>
              <li>
                <DefaultLink
                  font="14px"
                  color="var(--white)"
                  to="/privacy-policy"
                >
                  Privacy Policy
                </DefaultLink>
              </li>
              <li>
                {(() => {
                  if (isAndroid) {
                    return (
                      <DefaultLink
                        font="14px"
                        color="var(--white)"
                        to={
                          data.prismicLandingPageDetails.data
                            .app_link_for_android.text
                        }
                      >
                        Download
                      </DefaultLink>
                    )
                  } else if (isIOS) {
                    return (
                      <DefaultLink
                        font="14px"
                        color="var(--white)"
                        to={
                          data.prismicLandingPageDetails.data
                            .app_link_for_app_store.text
                        }
                      >
                        Download
                      </DefaultLink>
                    )
                  } else {
                    return (
                      <SimpleButton
                        font="14px"
                        color="var(--white)"
                        onClick={openModal}
                      >
                        Download
                      </SimpleButton>
                    )
                  }
                })()}
              </li>
            </ul>
          </NavListTwo>
          <FollowSection>
            <h1>{data.prismicLandingPageDetails.data.follow_us_title.text}</h1>
            <SocialMedia id="s-icon">
              <a
                className="s-icon"
                target="_blank"
                rel="noreferrer"
                href={data.prismicLandingPageDetails.data.instagram_link.text}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                  viewBox="0 0 26 26"
                  width="15px"
                  height="15px"
                >
                  <path d="M 7.546875 0 C 3.390625 0 0 3.390625 0 7.546875 L 0 18.453125 C 0 22.609375 3.390625 26 7.546875 26 L 18.453125 26 C 22.609375 26 26 22.609375 26 18.453125 L 26 7.546875 C 26 3.390625 22.609375 0 18.453125 0 Z M 7.546875 2 L 18.453125 2 C 21.527344 2 24 4.46875 24 7.546875 L 24 18.453125 C 24 21.527344 21.53125 24 18.453125 24 L 7.546875 24 C 4.472656 24 2 21.53125 2 18.453125 L 2 7.546875 C 2 4.472656 4.46875 2 7.546875 2 Z M 20.5 4 C 19.671875 4 19 4.671875 19 5.5 C 19 6.328125 19.671875 7 20.5 7 C 21.328125 7 22 6.328125 22 5.5 C 22 4.671875 21.328125 4 20.5 4 Z M 13 6 C 9.144531 6 6 9.144531 6 13 C 6 16.855469 9.144531 20 13 20 C 16.855469 20 20 16.855469 20 13 C 20 9.144531 16.855469 6 13 6 Z M 13 8 C 15.773438 8 18 10.226563 18 13 C 18 15.773438 15.773438 18 13 18 C 10.226563 18 8 15.773438 8 13 C 8 10.226563 10.226563 8 13 8 Z" />
                </svg>
              </a>
              <a
                className="s-icon"
                target="_blank"
                rel="noreferrer"
                href={data.prismicLandingPageDetails.data.facebook_link.text}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                  viewBox="0 0 24 24"
                  width="15px"
                  height="15px"
                >
                  <path d="M 21.800781 0 L 2.199219 0 C 1 0 0 1 0 2.199219 L 0 21.800781 C 0 23 1 24 2.199219 24 L 12 24 L 12 14 L 9 14 L 9 11 L 12 11 L 12 8 C 12 5.5 13 4 16 4 L 19 4 L 19 7 L 17.699219 7 C 16.800781 7 16 7.800781 16 8.699219 L 16 11 L 20 11 L 19.5 14 L 16 14 L 16 24 L 21.800781 24 C 23 24 24 23 24 21.800781 L 24 2.199219 C 24 1 23 0 21.800781 0 Z" />
                </svg>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={data.prismicLandingPageDetails.data.youtube_url.text}
              >
                <svg
                  className="s-icon-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                  viewBox="0 0 50 50"
                  width="20px"
                  height="20px"
                >
                  <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z" />
                </svg>
              </a>
            </SocialMedia>
          </FollowSection>
        </FooterContentWrapper>
        <Copyright />
      </MainWrapper>
      <BottomColor />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <TheModal />
        <button className="close-modal" onClick={closeModal}>
          <i class="tim-icons icon-simple-remove"></i>
        </button>
      </Modal>
    </FooterWrapper>
  )
}

export default Footer

const query = graphql`
  {
    prismicLandingPageDetails {
      data {
        subscribe_background_image {
          # localFile {
          #   childImageSharp {
          #     fluid {
          #       src
          #     }
          #   }
          # }
          url
        }
        instagram_link {
          text
        }
        facebook_link {
          text
        }
        youtube_url {
          text
        }
        follow_us_title {
          text
        }
        app_link_for_app_store {
          text
        }
        app_link_for_android {
          text
        }
      }
    }
  }
`

import styled from "styled-components"
import BannerImage from "../../../images/banner-home.jpg"

export const HeroWrapper = styled.div`
  height: 100vh;
  background-image: ${({ BG }) => `url(${BG})` || `url(${BannerImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  ::before {
    height: 100vh;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 1920px) {
    height: 100vh;
  }
`

export const HeroContentWrapper = styled.div`
  display: flex;
  align-items: left;
  max-width: 800px;
  padding-top: 350px;

  @media (max-width: 1920px) {
    max-width: 570px;
    padding-top: 25vh;
  }

  @media (max-width: 992px) {
    padding-top: 20vh;
    max-width: 100%;
  }
`
export const HeroContentBack = styled.div`
  z-index: 100;
  width: 100%;

  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
  }
`

export const HeroContent = styled.div`
  background-image: ${({ BG }) => `url(${BG})`};
  background-repeat: no-repeat;
  background-position: left;
  min-height: 600px;
  min-width: 600px;

  @media (max-width: 1920px) {
    min-height: 57vh;
    min-width: 50vw;
  }

  @media (max-width: 1200px) {
    margin: 0px 20px;
  }

  @media (max-width: 992px) {
    margin: 0px;
    max-width: 570px;
  }
`

export const HeroText = styled.div`
  padding-top: 250px;
  margin-left: 150px;
  max-width: 800px;

  @media (max-width: 1920px) {
    padding-top: 21vh;
    margin-left: 150px;
    max-width: 458px;
  }

  > h1 {
    color: var(--primaryColor);
    font-size: 25px;

    @media (max-width: 1920px) {
      font-size: var(--heroText);
    }

    @media (max-width: 992px) {
      font-size: var(--heroMobileText);
    }
  }

  > p {
    color: var(--white);
    margin-top: 30px;
    font-weight: var(--semiBold);
    font-size: 45px;

    @media (max-width: 1920px) {
      font-size: var(--heroTextDetails);
    }

    @media (max-width: 992px) {
      font-size: var(--heroMobileTextDetails);
    }

    @media (max-width: 576px) {
      font-size: 25px;
      padding-left: 4px;
    }
  }

  @media (max-width: 1440px) {
    margin-left: 127px;
  }

  @media (max-width: 1200px) {
    max-width: 397px;
  }

  @media (max-width: 576px) {
    margin-left: 72px;
    margin-right: 10px;
  }
`

export const AboutArrow = styled.div`
  display: flex;
  justify-content: center;
  z-index: 200;
  position: absolute;
  bottom: 100px;
  margin: auto;
  left: 0;
  right: 0;

  > a {
    width: 80px;
  }

  @media (max-width: 1920px) {
    bottom: 40px;

    > a {
      width: 50px;
    }
  }
`

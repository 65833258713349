import styled from "styled-components"

export const NavWrapper = styled.nav`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: "20px 0";
  transition: 0.3s;

  @media (max-width: 992px) {
    padding: 0;
  }
`
export const MainNav = styled.div`
  max-width: 1140px;
  padding: 20px 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: var(--navbarHeight);
  border-bottom: 1px solid var(--sliver);

  @media (max-width: 1200px) {
    margin: 0px 20px;
  }

  @media (max-width: 992px) {
    max-width: 720px;
    margin: 0 auto;
    padding: 0 20px;
  }

  @media (max-width: 576px) {
    max-width: 540px;
  }
`

export const LogoWrapper = styled.div``

export const DownloadWrapper = styled.div`
  display: block;

  @media (max-width: 992px) {
    display: none;
  }
`

export const NavItems = styled.ul`
  display: flex;

  @media (max-width: 992px) {
    overflow: hidden;
    flex-direction: column;
    width: calc(100% - 1.4rem);
    border-radius: 0.2857rem;
    background: var(--bostonBlue);
    max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
    padding: ${({ isOpen }) => (isOpen ? "1.4rem" : "0")};
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: var(--portGore);
    transition: 0.3s;
  }
`

export const Hamburger = styled.div`
  flex-direction: column;
  cursor: pointer;
  display: none;

  span {
    height: 1px;
    width: 22px;
    background: var(--white);
    margin-bottom: 4px;
    border-radius: 1px;
  }

  span:nth-child(2) {
    width: 17px;
    margin-top: 3px;
  }

  span:nth-child(3) {
    margin-top: 3px;
  }

  @media (max-width: 992px) {
    display: flex;
  }
`

export const MobileNavButton = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: block;
    margin-top: 20px;
  }
`

export const MobileNavBar = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
`

export const MobileNavTitle = styled.div`
  font-size: var(--navTitle);
  font-weight: var(--semiBold);
  color: var(--white);
`

export const RemoveNav = styled.div`
  font-size: var(--removeNav);
  color: var(--white);
`

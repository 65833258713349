import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  WhyWrapper,
  ItemWrapper,
  ItemGrid,
  LeftContent,
  ItemContentWrapper,
  ItemContent,
  RightContent,
} from "./styles"
import { MainWrapper } from "components"
import { WhySlider } from "./slider"

export const Why = () => {
  const data = useStaticQuery(query)

  return (
    <WhyWrapper
      BG={data.prismicLandingPageDetails.data.why_background_image.url}
      id="why"
    >
      <MainWrapper>
        <ItemWrapper>
          <ItemGrid>
            <LeftContent>
              <ItemContentWrapper>
                <ItemContent>
                  <h1>
                    {
                      data.prismicLandingPageDetails.data.stable_reliable_title
                        .text
                    }
                  </h1>
                  <p>
                    {
                      data.prismicLandingPageDetails.data
                        .stable_reliable_details.text
                    }
                  </p>
                </ItemContent>
                <ItemContent>
                  <h1>
                    {
                      data.prismicLandingPageDetails.data.full_automation_title
                        .text
                    }
                  </h1>
                  <p>
                    {
                      data.prismicLandingPageDetails.data
                        .full_automation_detaills.text
                    }
                  </p>
                </ItemContent>
                <ItemContent>
                  <h1>
                    {
                      data.prismicLandingPageDetails.data
                        .diversity_compatibility_title.text
                    }
                  </h1>
                  <p>
                    {
                      data.prismicLandingPageDetails.data
                        .diversity_and_compatibility_details.text
                    }
                  </p>
                </ItemContent>
              </ItemContentWrapper>
            </LeftContent>
            <RightContent>
              <WhySlider />
            </RightContent>
          </ItemGrid>
        </ItemWrapper>
      </MainWrapper>
    </WhyWrapper>
  )
}

const query = graphql`
  {
    prismicLandingPageDetails {
      data {
        why_background_image {
          # localFile {
          #   childImageSharp {
          #     fluid {
          #       src
          #     }
          #   }
          # }
          url
        }
        stable_reliable_title {
          text
        }
        stable_reliable_details {
          text
        }
        full_automation_title {
          text
        }
        full_automation_detaills {
          text
        }
        diversity_compatibility_title {
          text
        }
        diversity_and_compatibility_details {
          text
        }
      }
    }
  }
`

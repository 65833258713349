import styled from "styled-components"

export const AboutWrapper = styled.section`
  background-image: ${({ BG }) => `url(${BG})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-height: 680px;
`
export const ItemWrapper = styled.div`
  display: flex;

  @media (max-width: 1200px) {
    padding: 0px 20px;
  }

  @media (max-width: 992px) {
    max-width: 720px;
    margin: 0 auto;
  }
`
export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`

export const LeftContent = styled.div`
  padding-top: 255px;

  > h1 {
    font-size: var(--subscribeTitle);
    color: var(--blizzardBlue);
    margin-bottom: 20px;
    line-height: 1.05;
  }

  > p {
    color: var(--blizzardBlue);
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 300;
    line-height: 1.4;
  }

  @media (max-width: 992px) {
    padding-top: 100px;
  }
`

export const RightContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 70px 0;
`

export const ItemContent = styled.div`
  max-width: 223px;
  padding: 34px 28px 44px 28px;
  margin: 20px 15px;
  color: var(--white);
  opacity: 0.9;
  border-radius: 3px;

  > h1 {
    font-size: 1.6625rem;
    font-weight: var(--semiBold);
    margin-bottom: 10px;
  }

  > p {
    font-size: 15px;
    line-height: 1.4;
  }

  :hover {
    opacity: 1;
  }
`
export const Icon = styled.div`
  > i {
    color: var(--white);
    font-size: 33px;
    margin-bottom: 20px;
  }
`

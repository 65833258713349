import styled from "styled-components"
import { Link } from "gatsby"

export const DefaultLink = styled(Link)`
  color: ${({ color }) => color || "var(--black)"};
  font-size: ${({ font }) => font || "var(--commonTitle)"};
  margin: 7px 0;
  font-weight: bold;

  :hover {
    transform: translateY(-1px);
  }

  :active {
    transform: translateY(1px);
  }

  @media (max-width: 1920px) {
    font-weight: normal;
  }
`

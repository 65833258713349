import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  ContactWrapper,
  ContactHeader,
  MainContact,
  LeftContent,
  ContactTitle,
  Form,
  FormContent,
  InputWrapper,
  FirstFormSection,
  AllFormSection,
  RightSide,
  RightContent,
  RightItem,
  SubmitSection,
  MainContactWrapper,
} from "./styles"
import { MainWrapper, DefaultButton } from "components"

export const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    notes: "",
  })
  const [message, setMessage] = useState("")

  //set form data
  const handleInput = e => {
    const copyFormData = { ...formData }
    copyFormData[e.target.name] = e.target.value
    setFormData(copyFormData)
  }

  const finalData = {
    lead_name: `${formData.firstName} ${formData.lastName}`,
    email_id: formData.email,
    notes: formData.notes,
    suorce: "website",
  }

  //handle form submit
  const sendData = async e => {
    e.preventDefault()

    //start spining
    const spining = document.querySelector(".my-spiner-2")
    spining.classList.add("show")

    //remove button
    document.querySelector("#submit2").classList.add("hide")

    try {
      // const response =
      await fetch("https://erp.loop-control.com/api/resource/Lead", {
        method: "post",
        body: JSON.stringify(finalData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${process.env.AUTHORIZATION_FORM}`,
        },
      })

      // const json = await response.json()
      // console.log("Success:", JSON.stringify(json))

      setMessage("Message Sent!")

      //remove spining
      spining.classList.remove("show")
    } catch (error) {
      // console.error("Error:", error)
      setMessage("Error please check fields!")
      document.querySelector("#submit2").classList.add("show")

      //remove spining
      spining.classList.remove("show")
    }
  }

  //query from graphql
  const data = useStaticQuery(query)

  return (
    <ContactWrapper
      BG={data.prismicLandingPageDetails.data.get_in_touch_background_image.url}
      id="contact"
    >
      <MainWrapper>
        <MainContactWrapper>
          <ContactHeader>
            <h1>
              {data.prismicLandingPageDetails.data.get_in_touch_tilte.text}
            </h1>
            <p>
              {data.prismicLandingPageDetails.data.get_in_touch_detials.text}
            </p>
          </ContactHeader>
          <MainContact>
            <LeftContent>
              <ContactTitle>
                {data.prismicLandingPageDetails.data.contact_title.text}
              </ContactTitle>
              <Form onSubmit={sendData}>
                <FirstFormSection>
                  <FormContent className="double-row">
                    <label>
                      {
                        data.prismicLandingPageDetails.data
                          .contact_first_name_title.text
                      }
                    </label>
                    <InputWrapper>
                      <i
                        className={`tim-icons ${data.prismicLandingPageDetails.data.contact_first_name_icon.text}`}
                      ></i>
                      <input
                        name="firstName"
                        onChange={handleInput}
                        type="text"
                        required
                        placeholder={
                          data.prismicLandingPageDetails.data
                            .contact_first_name_placeholder.text
                        }
                      />
                    </InputWrapper>
                  </FormContent>
                  <FormContent className="double-row">
                    <label>
                      {
                        data.prismicLandingPageDetails.data
                          .contact_last_name_title.text
                      }
                    </label>
                    <InputWrapper>
                      <i
                        className={`tim-icons ${data.prismicLandingPageDetails.data.contact_last_name_icon.text}`}
                      ></i>
                      <input
                        type="text"
                        name="lastName"
                        required
                        onChange={handleInput}
                        placeholder={
                          data.prismicLandingPageDetails.data
                            .contact_last_name_placeholder.text
                        }
                      />
                    </InputWrapper>
                  </FormContent>
                </FirstFormSection>
                <AllFormSection>
                  <FormContent>
                    <label>
                      {
                        data.prismicLandingPageDetails.data.contact_email_title
                          .text
                      }
                    </label>
                    <InputWrapper>
                      <i
                        className={`tim-icons ${data.prismicLandingPageDetails.data.contact_email_icon.text}`}
                      ></i>
                      <input
                        type="email"
                        name="email"
                        onChange={handleInput}
                        required
                        placeholder={
                          data.prismicLandingPageDetails.data
                            .contact_email_placeholder.text
                        }
                      />
                    </InputWrapper>
                  </FormContent>
                </AllFormSection>
                <AllFormSection>
                  <FormContent>
                    <label>
                      {
                        data.prismicLandingPageDetails.data
                          .contact_message_title.text
                      }
                    </label>
                    <InputWrapper>
                      <textarea
                        name="notes"
                        onChange={handleInput}
                        rows="6"
                        type="text"
                      />
                    </InputWrapper>
                  </FormContent>
                </AllFormSection>
                <SubmitSection>
                  {/* <Robot>
                    <input type="checkbox" />
                    <label>I'm not a robot</label>
                  </Robot> */}
                  <DefaultButton id="submit2" type="submit">
                    {
                      data.prismicLandingPageDetails.data
                        .get_in_touch_button_name.text
                    }
                  </DefaultButton>
                  <div className="loader-2 my-spiner-2"></div>
                  <div className="success-2"> {message}</div>
                </SubmitSection>
              </Form>
            </LeftContent>
            <RightSide>
              <ContactTitle>
                {data.prismicLandingPageDetails.data.contact_info_title.text}
              </ContactTitle>
              <RightContent>
                {/* <RightItem>
                  <i
                    className={`tim-icons ${data.prismicLandingPageDetails.data.contact_address_icon.text}`}
                  ></i>
                  <span>
                    {data.prismicLandingPageDetails.data.contact_address.text}
                  </span>
                </RightItem> */}
                <RightItem>
                  <i
                    className={`tim-icons ${data.prismicLandingPageDetails.data.phone_icon.text}`}
                  ></i>
                  <span>
                    {data.prismicLandingPageDetails.data.phone_number.text}
                  </span>
                </RightItem>
                <RightItem>
                  <i
                    className={`tim-icons ${data.prismicLandingPageDetails.data.email_address_icon.text}`}
                  ></i>
                  <span>
                    {data.prismicLandingPageDetails.data.email_address.text}
                  </span>
                </RightItem>
              </RightContent>
            </RightSide>
          </MainContact>
        </MainContactWrapper>
      </MainWrapper>
    </ContactWrapper>
  )
}

const query = graphql`
  {
    prismicLandingPageDetails {
      data {
        get_in_touch_background_image {
          url
          # localFile {
          #   childImageSharp {
          #     fluid {
          #       src
          #     }
          #   }
          # }
        }
        get_in_touch_tilte {
          text
        }
        get_in_touch_robot_txt {
          text
        }
        get_in_touch_detials {
          text
        }
        get_in_touch_button_name {
          text
        }
        contact_title {
          text
        }
        contact_info_title {
          text
        }
        # contact_address {
        #   text
        # }
        # contact_address_icon {
        #   text
        # }
        phone_icon {
          text
        }
        phone_number {
          text
        }
        email_address {
          text
        }
        email_address_icon {
          text
        }
        contact_first_name_icon {
          text
        }
        contact_first_name_placeholder {
          text
        }
        contact_first_name_title {
          text
        }
        contact_last_name_icon {
          text
        }
        contact_last_name_placeholder {
          text
        }
        contact_last_name_title {
          text
        }
        contact_email_icon {
          text
        }
        contact_email_placeholder {
          text
        }
        contact_email_title {
          text
        }
        contact_message_title {
          text
        }
      }
    }
  }
`

import React from "react"

import { NavList } from "./styles"
import { AnchorLinkButton } from "components"

const NavItem = ({ name, to }) => {
  return (
    <NavList>
      <AnchorLinkButton href={to} fontSize="var(--navitem)">
        {name}
      </AnchorLinkButton>
    </NavList>
  )
}

export default NavItem

import styled from "styled-components"

export const WhyWrapper = styled.section`
  min-height: 680px;
  background-image: ${({ BG }) => `url(${BG})`};
`

export const ItemWrapper = styled.div`
  @media (max-width: 992px) {
    margin: 0 auto;
    max-width: 720px;
  }
`
export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;

  @media (max-width: 1150px) {
    grid-template-columns: 1fr;
  }
`
export const LeftContent = styled.div`
  padding: 8vh 0;

  @media (max-width: 992px) {
    padding-bottom: 0;
  }
`

export const ItemContentWrapper = styled.div`
  padding: 10px 10px 30px 10px;
`

export const ItemContent = styled.div`
  padding: 35px 30px;
  display: block;
  border-radius: 3px;
  transition: 0.3s;

  > h1 {
    color: var(--white);
    margin-bottom: 20px;
    font-size: 1.4375rem;
    font-weight: var(--normal);
  }

  > p {
    color: var(--white);
    font-size: 15px;
    font-weight: var(--normal);
    line-height: 1.4;
  }

  :hover {
    background: var(--dodgerBlue);
  }
`

export const RightContent = styled.div`
  padding: 150px 0;
  position: relative;

  @media (max-width: 1150px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`

import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const SliderWrapper = styled.div`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  @media (max-width: 992px) {
    max-width: 300px;
  }
`
export const SliderImg = styled(GatsbyImage)`
  border-radius: 5px;
  padding: 20px 0 20px 20px;
`
export const SliderButton = styled.button`
  border: 1px solid var(--white);
  padding: 11px 13px;
  color: var(--white);
  border-radius: 50%;
  background-color: transparent;
  margin-top: 27px;
  cursor: pointer;
  transition: 0.3s;

  > i {
    font-weight: bold;
  }

  &:nth-child(1) {
    margin-right: 10px;
  }
  &:nth-child(2) {
    margin-left: 10px;
  }

  :hover {
    background-color: #0079e8;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 30px;

  @media (max-width: 992px) {
    justify-content: center;
    margin: 0;
  }
`

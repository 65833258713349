import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  :root {
    /* ------------------------- color ------------------------- */
    --primaryColor: #47bcfb;
    --juneBud: #bbdd52;
    --white: #fff;
    --darkElectricBlue: #525f7f;
    --black: #000;
    --blizzardBlue: rgba(255, 255, 255, 0.8);
    --pickledBluewood: #2B3553;
    --denim: #1e59bd;
    --dustyGrey: #9A9A9A;
    --dodgerBlue: #1d8cf8;
    --brinkPink: #fd5d93;
    --green: #00b300;
    --red: #ff0000;
    --brightTurquoise: #00f2c3;
    --eastBay: rgba(255, 255, 255, 0.6);
    --sliver: #c1c1C1C1;
    --portGore: #1f2251;
    --navBack: rgba(15,8,24,0.8);
    --coolGreen: #1B59BE;

    /* ------------------------- box shadow & border ------------------------- */
    //navbar
    --navbarBoxShadow: 0px 2px 12px #0000001D;
    //button
    --buttonBorderRadious: 32px;
    /*  homepage  */
    //blog section
    --blogItemBoxShadow: 0px 2px 16px #394E5D36;
    --blogImageBorderRadious: 4px;

    /* ------------------------- font size ------------------------- */
    /*  common font size  */
    --commonTitle: 20px;
    //navbar
    --navTitle: 1.2495rem;
    --navitem: 0.875rem;
    --removeNav: 1.2495rem;
    //subscribe
    --subscribeTitle: 2.0625rem;
    --subscribeText: 1.4375rem;;
    //button
    --buttonText: 0.875rem;
    //copyright
    --copyrightText: 14px; 
    /*  homepage  */
    //hero
    --heroText: 1.0625rem;
    --heroTextDetails: 31px;
    --heroMobileText: 1rem;
    --heroMobileTextDetails: 28px;
    //section common
    --sectionTitle: 32px;
    //lorem ipsum section
    --loremDetails: 18px;
    //blog section
    --blogSectionDetails: 16px;

     /* ------------------------- font weight ------------------------- */
    --normal: 400;
    --semiBold: 600;
    --bold: 700;

     /* ------------------------- transition ------------------------- */
     --defaultTransition: 0.3s;

     /* ------------------------- navbar height ------------------------- */
     --navbarHeight: 132px;
  }
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body{
    font-family: 'Space Grotesk', sans-serif;
    color: var(--primaryColor);
    font-weight: var(--normal);
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.2px;
  }
    ol, ul {
    list-style: none;
  }
  a {
    text-decoration: none;
    display: inline-block;
  }
  button {
    font-family: 'Space Grotesk', sans-serif;
  }
  button:focus {
    outline: 0;
  }
  html {
    scroll-behavior: smooth;
  }
  input:focus {
    outline-offset: 0;
  }
  *:focus {
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
`

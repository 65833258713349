import styled from "styled-components"

export const SubscribeWrapper = styled.section`
  background-image: ${({ BG }) => `url(${BG})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`
export const ItemWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1200px) {
    display: block;
  }

  @media (max-width: 576px) {
    max-width: 540px;
  }
`

export const LeftContentWidth = styled.div`
  position: relative;
`

export const SubsForm = styled.form`
  max-width: 387px;
`

export const FormInput = styled.input`
  padding: 10px 18px;
  border-radius: 2rem;
  font-size: 0.75rem;
  height: calc(2.25rem + 2px);
  border: 1px solid #cad1d7;
  width: 100%;
  background-color: transparent;
  border-color: var(--pickledBluewood);
  transition: 0.3s;
  color: var(--white);
  margin-top: 40px;

  :focus {
    border-color: var(--juneBud);
  }

  ::placeholder {
    color: var(--white);
    font-family: "Space Grotesk", sans-serif;
  }
`

export const SubmitButton = styled.button`
  background-color: var(--primaryColor);
  border-radius: 30px;
  padding: 0.5rem 0.7rem;
  border: 0;
  margin-top: 13px;
  color: var(--denim);
  font-weight: bold;
  cursor: pointer;

  :hover {
    background-color: var(--juneBud);
  }
`

export const LeftContentWrapper = styled.div`
  width: 50%;
  padding-top: 100px;
  min-height: 600px;

  @media (max-width: 1400px) {
    width: 40%;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`

export const LeftContent = styled.div`
  top: 0;
  position: absolute;
  right: 0;
  max-width: 550px;

  @media (max-width: 1200px) {
    position: inherit;
  }

  @media (max-width: 1400px) {
    max-width: 400px;
  }

  @media (max-width: 992px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  > h1 {
    font-size: var(--subscribeTitle);
    color: var(--juneBud);
    margin-bottom: 60px;
  }

  > p {
    color: var(--blizzardBlue);
    font-size: var(--subscribeText);
    padding-right: 30px;
    font-weight: var(--semiBold);
  }
`

export const RightContent = styled.div`
  width: 50%;
  min-height: 600px;
  position: relative;

  @media (max-width: 1400px) {
    width: 60%;
  }

  @media (max-width: 1200px) {
    width: 100%;
    display: block;
    background-position: bottom center;
  }

  @media (max-width: 992px) {
    min-height: 293px;

    > img {
      display: block !important;
    }
  }

  @media (max-width: 450px) {
    min-height: 200px;
  }

  background-image: ${({ BG }) => `url(${BG})`};
  background-repeat: no-repeat;
  background-position: bottom left;

  @media (max-width: 992px) {
    background-image: none;
  }

  > img {
    width: 100%;
    display: none;
    position: absolute;
    bottom: 0;
  }
`

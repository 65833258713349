import styled from "styled-components"

export const AnchorLinkButton = styled.a`
  color: ${({ color }) => color || "var(--white)"};
  font-size: ${({ fontSize }) => fontSize || "var(--commonTitle)"};
  font-weight: ${({ fontWeight }) => fontWeight || "var(--bold)"};
  z-index: 200;

  :hover {
    color: ${({ color }) => color || "var(--blizzardBlue)"};
  }

  :any-link:active {
    color: var(--juneBud);
  }
`

import styled from "styled-components"
import BannerImage from "../../images/banner-home.jpg"
import Shade from "../../images/shade.png"

export const HeroWrapper = styled.div`
  height: 100vh;
  background-image: ${({ BG }) => `url(${BG})` || `url(${BannerImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  ::before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    bottom: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url(${Shade});
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
  }
`

export const HeroContentWrapper = styled.div`
  display: flex;
  align-items: left;
  max-width: 570px;
  padding-top: 25vh;

  @media (max-width: 992px) {
    padding-top: 20vh;
    max-width: 100%;
  }
`
export const HeroContentBack = styled.div`
  z-index: 100;
  width: 100%;

  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
  }
`

export const HeroContent = styled.div`
  background-image: ${({ BG }) => `url(${BG})`};
  background-repeat: no-repeat;
  background-position: left;
  min-height: 57vh;
  min-width: 50vw;

  @media (max-width: 1200px) {
    margin: 0px 20px;
  }

  @media (max-width: 992px) {
    margin: 0px;
    max-width: 570px;
  }
`

export const HeroText = styled.div`
  padding-top: 15vh;
  margin-left: 150px;
  max-width: 458px;

  > h1 {
    color: var(--white);
    font-size: 100px;

    @media (max-width: 992px) {
      font-size: 50px;
    }
  }

  > h6 {
    color: var(--primaryColor);
    font-size: 25px;

    @media (max-width: 992px) {
      font-size: var(--heroMobileText);
    }
  }

  > p {
    color: var(--white);
    margin-top: 30px;
    font-size: 15px;

    @media (max-width: 576px) {
      padding-left: 4px;
    }
  }

  @media (max-width: 1440px) {
    margin-left: 127px;
  }

  @media (max-width: 1200px) {
    max-width: 397px;
  }

  @media (max-width: 576px) {
    margin-left: 72px;
  }
`

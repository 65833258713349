import styled from "styled-components"

export const DefaultButton = styled.button`
  color: ${({ color }) => color || "var(--juneBud)"};
  background-color: transparent;
  padding: 10px 22px;
  border: 1px solid ${({ color }) => color || "var(--juneBud)"};
  border-radius: var(--buttonBorderRadious);
  font-size: var(--buttonText);
  transition: var(--defaultTransition);
  cursor: pointer;
  line-height: 1.35em;

  :hover {
    transform: translateY(-1px);
  }

  :active {
    transform: translateY(1px);
  }

  > a {
    color: var(--juneBud);
  }
`
export const SimpleButton = styled.button`
  border: 0;
  padding: 0;
  background-color: transparent;
  color: var(--white);
  margin-top: 3px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;

  :hover {
    transform: translateY(-1px);
  }

  :active {
    transform: translateY(1px);
  }

  @media (max-width: 1920px) {
    font-weight: normal;
  }
`

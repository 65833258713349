import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  HeroContentWrapper,
  HeroWrapper,
  HeroContent,
  HeroText,
  HeroContentBack,
} from "./styles"
import { MainWrapper } from "components"

export const NoRoute = ({ name, NoRouteTitle, NoRouteDetails }) => {
  const data = useStaticQuery(query)

  return (
    <HeroWrapper
      BG={data.prismicLandingPageDetails.data.main_background_image.url}
    >
      <MainWrapper>
        <HeroContentWrapper>
          <HeroContentBack>
            <HeroContent
              BG={
                data.prismicLandingPageDetails.data
                  .hero_section_background_image.url
              }
            >
              <HeroText>
                <h1>
                  <b>{name}</b>
                </h1>
                <h6>{NoRouteTitle}</h6>
                <p>{NoRouteDetails}</p>
              </HeroText>
            </HeroContent>
          </HeroContentBack>
        </HeroContentWrapper>
      </MainWrapper>
    </HeroWrapper>
  )
}

const query = graphql`
  {
    prismicLandingPageDetails {
      data {
        main_background_image {
          # localFile {
          #   childrenImageSharp {
          #     fluid(quality: 100) {
          #       src
          #     }
          #   }
          # }
          url
        }
        hero_title {
          text
        }
        hero_description {
          text
        }
        hero_section_background_image {
          url
        }
        arrow {
          url
          alt
        }
      }
    }
  }
`

import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const Logo = () => {
  const data = useStaticQuery(query)

  return (
    <Link to="/">
      <img
        style={{ width: "55vw", maxWidth: "100px" }}
        src={data.prismicSiteDescription.data.logo.url}
        alt={data.prismicSiteDescription.data.logo.alt}
      />
    </Link>
  )
}

export default Logo

const query = graphql`
  {
    prismicSiteDescription {
      data {
        logo {
          url
          alt
        }
      }
    }
  }
`

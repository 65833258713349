import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  ItemWrapper,
  AboutWrapper,
  ItemGrid,
  LeftContent,
  RightContent,
  ItemContent,
  Icon,
} from "./styles"
import { MainWrapper } from "components"

export const About = () => {
  const data = useStaticQuery(query)

  return (
    <AboutWrapper
      BG={data.prismicLandingPageDetails.data.about_background_image.url}
      id="about"
    >
      <MainWrapper>
        <ItemWrapper>
          <ItemGrid>
            <LeftContent>
              <h1>{data.prismicLandingPageDetails.data.about_title.text}</h1>
              <p>
                {data.prismicLandingPageDetails.data.about_description.text}
              </p>
              {/* <DefaultButton color="var(--white)">Learn More</DefaultButton> */}
            </LeftContent>
            <RightContent>
              <ItemContent id="about-item">
                <Icon>
                  <i
                    className={`tim-icons ${data.prismicLandingPageDetails.data.about_electrical_icon.text}`}
                  ></i>
                </Icon>
                <h1>
                  {data.prismicLandingPageDetails.data.about_electrical.text}
                </h1>
                <p>
                  {
                    data.prismicLandingPageDetails.data
                      .about_energy_saving_details.text
                  }
                </p>
              </ItemContent>
              <ItemContent id="about-item">
                <Icon>
                  <i
                    className={`tim-icons ${data.prismicLandingPageDetails.data.about_energy_saving__icon.text}`}
                  ></i>
                </Icon>
                <h1>
                  {
                    data.prismicLandingPageDetails.data
                      .about_energy_saving_title.text
                  }
                </h1>
                <p>
                  {
                    data.prismicLandingPageDetails.data.about_electrical_details
                      .text
                  }
                </p>
              </ItemContent>
              <ItemContent id="about-item">
                <Icon>
                  <i
                    className={`tim-icons ${data.prismicLandingPageDetails.data.security_title_icon.text}`}
                  ></i>
                </Icon>
                <h1>
                  {data.prismicLandingPageDetails.data.security_title.text}
                </h1>
                <p>
                  {
                    data.prismicLandingPageDetails.data.about_security_details
                      .text
                  }
                </p>
              </ItemContent>
              <ItemContent id="about-item">
                <Icon>
                  <i
                    className={`tim-icons ${data.prismicLandingPageDetails.data.intercom_icon.text}`}
                  ></i>
                </Icon>
                <h1>
                  {data.prismicLandingPageDetails.data.intercom_title.text}
                </h1>
                <p>
                  {data.prismicLandingPageDetails.data.intercom_details.text}
                </p>
              </ItemContent>
              <ItemContent id="about-item">
                <Icon>
                  <i
                    className={`tim-icons ${data.prismicLandingPageDetails.data.about_sensors_icon_nucleo_icon_.text}`}
                  ></i>
                </Icon>
                <h1>
                  {data.prismicLandingPageDetails.data.about_sensors_title.text}
                </h1>
                <p>
                  {
                    data.prismicLandingPageDetails.data.about_sensors_details
                      .text
                  }
                </p>
              </ItemContent>
              <ItemContent id="about-item">
                <Icon>
                  <i
                    className={`tim-icons ${data.prismicLandingPageDetails.data.about_pet_products_icon_.text}`}
                  ></i>
                </Icon>
                <h1>
                  {data.prismicLandingPageDetails.data.pet_products_title.text}
                </h1>
                <p>
                  {
                    data.prismicLandingPageDetails.data
                      .about_pet_products_details.text
                  }
                </p>
              </ItemContent>
            </RightContent>
          </ItemGrid>
        </ItemWrapper>
      </MainWrapper>
    </AboutWrapper>
  )
}

const query = graphql`
  {
    prismicLandingPageDetails {
      data {
        about_background_image {
          # localFile {
          #   childImageSharp {
          #     fluid {
          #       src
          #     }
          #   }
          # }
          url
        }
        about_electrical {
          text
        }
        about_electrical_details {
          text
        }
        about_electrical_icon {
          text
        }
        about_energy_saving__icon {
          text
        }
        about_energy_saving_details {
          text
        }
        about_energy_saving_title {
          text
        }
        security_title_icon {
          text
        }
        security_title {
          text
        }
        about_security_details {
          text
        }
        intercom_details {
          text
        }
        intercom_icon {
          text
        }
        intercom_title {
          text
        }
        about_sensors_details {
          text
        }
        about_sensors_icon_nucleo_icon_ {
          text
        }
        about_sensors_title {
          text
        }
        about_pet_products_details {
          text
        }
        about_pet_products_icon_ {
          text
        }
        pet_products_title {
          text
        }
        subscribe_email_placeholder {
          text
        }
        subscribe_section_background_image {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        about_title {
          text
        }
        about_description {
          text
        }
      }
    }
  }
`

import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import Image from "../../images/icon.png"

//seo & Schema Markup components

export const Seo = ({ title, description, keywords, image, schemaMarkup }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultDescription,
          defaultImage,
          url,
          defaultKeywords,
          defaultTitle,
        },
      },
    }) => {
      const seo = {
        defaultTitle: defaultTitle,
        description: description || defaultDescription,
        image: `${image ? url + image : url + `${Image}`}`,
        keywords: `${keywords ? keywords + "," : defaultKeywords}`,
      }

      return (
        <Helmet title={title}>
          <html lang="en" />
          <meta name="title" content={title} />
          <meta name="image" content={seo.image} />
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keywords} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://loop-control.com/" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.image} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://loop-control.com/" />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={seo.description} />
          <meta property="twitter:image" content={seo.image} />
        </Helmet>
      )
    }}
  />
)

//get default seo data from gatsby-config.js
const query = graphql`
  {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultImage: image
        defaultKeywords: keywords
        url: siteUrl
      }
    }
  }
`

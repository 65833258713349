/* eslint-disable */
/* jsx-a11y/control-has-associated-label */
/* jsx-a11y/anchor-has-content */

import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { isAndroid, isIOS } from "react-device-detect"
import Modal from "react-modal"

import {
  LogoWrapper,
  MainNav,
  NavWrapper,
  Hamburger,
  NavItems,
  DownloadWrapper,
  MobileNavButton,
  MobileNavBar,
  MobileNavTitle,
  RemoveNav,
} from "./styles"
import NavItem from "./NavItem"
import { DefaultButton, TheModal } from "components"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "99999",
    overflow: "hidden",
  },
}

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)

  //for modal
  const [modalIsOpen, setModalIsOpen] = useState(false)

  function openModal() {
    setModalIsOpen(true)
  }

  function closeModal() {
    setModalIsOpen(false)
  }

  //data from graphql
  const data = useStaticQuery(query)

  return (
    <NavWrapper>
      <MainNav>
        <Hamburger onClick={() => setIsOpen(!isOpen)}>
          <span />
          <span />
          <span />
          <span />
        </Hamburger>

        <LogoWrapper>
          <Link to="/">
            <img
              style={{ width: "55vw", maxWidth: "100px" }}
              src={data.prismicSiteDescription.data.logo.url}
              alt={data.prismicSiteDescription.data.logo.alt}
            />
          </Link>
        </LogoWrapper>

        <NavItems isOpen={isOpen}>
          <MobileNavBar>
            <MobileNavTitle>loop</MobileNavTitle>
            <RemoveNav onClick={() => setIsOpen(!isOpen)}>
              <i
                className={`tim-icons ${data.prismicLandingPageDetails.data.close_css_classname.text}`}
              ></i>
            </RemoveNav>
          </MobileNavBar>
          <NavItem name="learn more" to="/#subscribe" />
          <NavItem name="about" to="/#about" />
          <NavItem name="get in touch" to="/#contact" />
          <NavItem name="shop" to="https://shop.loop-control.com" />

          {(() => {
            if (isAndroid) {
              return (
                <MobileNavButton>
                  <DefaultButton className="app-link">
                    <a
                      href={
                        data.prismicLandingPageDetails.data.app_link_for_android
                          .text
                      }
                    >
                      {data.prismicLandingPageDetails.data.nav_button_text.text}
                    </a>
                  </DefaultButton>
                </MobileNavButton>
              )
            } else if (isIOS) {
              return (
                <MobileNavButton>
                  <DefaultButton className="app-link">
                    <a
                      href={
                        data.prismicLandingPageDetails.data
                          .app_link_for_app_store.text
                      }
                    >
                      {data.prismicLandingPageDetails.data.nav_button_text.text}
                    </a>
                  </DefaultButton>
                </MobileNavButton>
              )
            } else {
              return (
                <MobileNavButton>
                  <DefaultButton onClick={openModal}>
                    Download Loop Control
                  </DefaultButton>
                </MobileNavButton>
              )
            }
          })()}
        </NavItems>

        <DownloadWrapper>
          {(() => {
            if (isAndroid) {
              return (
                <DefaultButton className="app-link">
                  <a
                    href={
                      data.prismicLandingPageDetails.data.app_link_for_android
                        .text
                    }
                  >
                    {data.prismicLandingPageDetails.data.nav_button_text.text}
                  </a>
                </DefaultButton>
              )
            } else if (isIOS) {
              return (
                <DefaultButton className="app-link">
                  <a
                    href={
                      data.prismicLandingPageDetails.data.app_link_for_app_store
                        .text
                    }
                  >
                    {data.prismicLandingPageDetails.data.nav_button_text.text}
                  </a>
                </DefaultButton>
              )
            } else {
              return (
                <DefaultButton onClick={openModal}>
                  Download Loop Control
                </DefaultButton>
              )
            }
          })()}
        </DownloadWrapper>
      </MainNav>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <TheModal />
        <button className="close-modal" onClick={closeModal}>
          <i class="tim-icons icon-simple-remove"></i>
        </button>
      </Modal>
    </NavWrapper>
  )
}

export default Navbar

const query = graphql`
  {
    prismicSiteDescription {
      data {
        logo {
          url
          alt
        }
      }
    }
    prismicLandingPageDetails {
      data {
        close_css_classname {
          text
        }
        app_link_for_app_store {
          text
        }
        app_link_for_android {
          text
        }
        nav_button_text {
          text
        }
      }
    }
  }
`

import React, { Fragment } from "react"

import { GlobalStyles } from "components"
import "./src/assets/fonts/nucleo-icons.css"
import "./src/assets/css/app.css"

//global styles for whole website
export const wrapPageElement = ({ element }) => (
  <Fragment>
    <GlobalStyles />
    {element}
  </Fragment>
)

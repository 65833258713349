import React, { Fragment, useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { SliderWrapper, SliderImg, ButtonWrapper, SliderButton } from "./styles"

const responsive = {
  tablet: {
    breakpoint: { max: 4000, min: 992 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 992, min: 0 },
    items: 1,
  },
}

export const WhySlider = () => {
  const data = useStaticQuery(query)
  const { items } = data.prismicLandingPageDetails.data.body[0]
  const [index, setIndex] = useState(0)
  const carouselRef = useRef()

  const next = () => {
    if (carouselRef.current) {
      carouselRef.current.next()
      setIndex((index + 1) % items.length)
    }
  }

  const prev = () => {
    if (carouselRef.current) {
      carouselRef.current.previous()
      setIndex((index - 1 + items.length) % items.length)
    }
  }

  return (
    <SliderWrapper>
      <Carousel
        swipeable={false}
        draggable={false}
        autoPlay
        infinite
        arrows={false}
        responsive={responsive}
        ref={carouselRef}
      >
        {items.map((item, i) => (
          <Fragment key={i}>
            <SliderImg
              image={
                item.gallery_image.localFile.childImageSharp.gatsbyImageData
              }
              width="300"
              height="400"
              alt={item.image_captions.text}
            />
          </Fragment>
        ))}
      </Carousel>
      <ButtonWrapper>
        <SliderButton onClick={prev}>
          <i className="tim-icons icon-minimal-left"></i>
        </SliderButton>
        <SliderButton onClick={next}>
          <i className="tim-icons icon-minimal-right"></i>
        </SliderButton>
      </ButtonWrapper>
    </SliderWrapper>
  )
}

const query = graphql`
  {
    prismicLandingPageDetails {
      data {
        body {
          ... on PrismicLandingPageDetailsDataBodyImageGallery {
            items {
              gallery_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 300, height: 400)
                  }
                }
              }
              image_captions {
                text
              }
            }
          }
        }
      }
    }
  }
`

// image={item.gallery_image.localFile.childImageSharp.fixed}

import styled from "styled-components"

export const ContactWrapper = styled.section`
  min-height: 850px;
  background-image: ${({ BG }) => `url(${BG})`};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`

export const MainContactWrapper = styled.div`
  @media (max-width: 1200px) {
    padding: 0px 20px;
  }
`

export const ContactHeader = styled.div`
  padding: 100px 0;
  display: block;
  color: var(--white);

  > h1 {
    font-size: 2.0625rem;
    margin-bottom: 20px;
    color: var(--blizzardBlue);
  }

  > p {
    max-width: 450px;
    line-height: 1.4;
    color: var(--blizzardBlue);
  }
`

export const MainContact = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`

export const LeftContent = styled.div`
  padding: 40px 32px;
  background-color: var(--portGore);
  border-radius: 7px 0 0 7px;

  @media (max-width: 992px) {
    border-radius: 7px 7px 0 0;
  }
`

export const ContactTitle = styled.div`
  color: var(--white);
  margin-bottom: 30px;
  font-size: 20px;

  @media (max-width: 992px) {
    text-align: center;
  }
`

export const Form = styled.form``

export const FormContent = styled.div`
  width: 100%;
  margin-bottom: 15px;

  > label {
    color: var(--eastBay);
    font-size: 0.75rem;
  }
`

export const InputWrapper = styled.div`
  margin-top: 10px;
  border: 1px solid #cad1d7;
  border-radius: 0.4285rem;
  border-color: #2b3553;

  > i {
    padding: 10px 0 10px 18px;
    opacity: 0.5;
  }

  > input {
    width: 80%;
    padding: 10px 18px 10px 18px;
    background-color: transparent;
    border: 0;
    color: var(--white);
  }

  > textarea {
    width: 100%;
    padding: 10px 18px 10px 18px;
    background-color: transparent;
    border: 0;
    color: var(--white);
  }
`

export const FirstFormSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`

export const AllFormSection = styled.div`
  display: block;
  width: 100%;
`
export const SubmitSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const Robot = styled.form`
  > input[type="checkbox"] {
    color: transparent;
  }
`

export const RightSide = styled.div`
  background-color: var(--dodgerBlue);
  border-radius: 0 7px 7px 0;
  padding: 40px 32px;

  @media (max-width: 992px) {
    border-radius: 0 0 7px 7px;
    margin-bottom: 80px;
  }
`

export const RightContent = styled.div`
  margin-top: 70px;
`

export const RightItem = styled.div`
  display: flex;
  margin: 40px 0;

  > i {
    font-size: 23px;
    margin-right: 15px;
    color: var(--white);
    padding-top: 4px;
  }

  > span {
    max-width: 180px;
    color: var(--white);
    font-size: 14px;
    line-height: 1.7;
  }

  @media (max-width: 992px) {
    justify-content: center;
  }
`

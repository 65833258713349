import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const CopyrightWrapper = styled.div`
  justify-content: center;
  display: flex;
  padding: 6px;
  font-size: 12px;
  color: var(--white);

  @media (max-width: 765px) {
    text-align: center;
  }
`

const Copyright = () => {
  const data = useStaticQuery(query)

  return (
    <CopyrightWrapper>
      {data.prismicLandingPageDetails.data.copyright_text.text}
    </CopyrightWrapper>
  )
}

export default Copyright

const query = graphql`
  {
    prismicLandingPageDetails {
      data {
        copyright_text {
          text
        }
      }
    }
  }
`
